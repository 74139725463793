
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { keyframes, Heading, extendTheme, ColorModeScript, Text, useColorMode, Button, Input, IconButton, Spinner, Spacer, ChakraProvider, Flex } from '@chakra-ui/react'
import { SunIcon, MoonIcon } from '@chakra-ui/icons'


function ForwardLink() {
  const { short_url } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`/lookup_short_url/${short_url}`);
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
        setIsFound(true);
      } else {
        setIsFound(false);
      }
      setIsLoading(false);
    }
   
    fetchData(); 

  }, [short_url]);


  if (isFound) {
    window.location.href = data.long_url;
    return null;
  } else if (isLoading) {
    return (
      <>
        <Spacer />
        <Spinner size="xl" />
        <Spacer />
      </>
    )
  } else {
    return (
      <>
        <Spacer />
        <Text> link not found (⁄ ⁄•⁄ω⁄•⁄ ⁄)</Text>
        <Spacer />
      </>
    )
  };
}


function MakeLink() {
  const [data, setData] = useState(null);
  const [input_url, setInputUrl] = useState('');
  const { colorMode, toggleColorMode } = useColorMode();

  async function handleClick(e) {
    e.preventDefault();
    try {
      const form = e.target
      const formData = new FormData(form);
      if (data) {
        await navigator.clipboard.writeText(`https://s.t9v.ca/l/${data.short_url}`);
      } else {
        const response = await fetch('create_short_url', {
          method: form.method,
          body: formData
        });
        const jsonData = await response.json();
        setData(jsonData);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  function handleChange(e) {
    setInputUrl(e.target.value)
  };

  const animation = keyframes`
    to {
     background-position: 100% center;
    } 
  `

  return (
    <>
      <IconButton icon={colorMode === 'light' ? <SunIcon/> : <MoonIcon/>} isRound="true" alignSelf="flex-end" onClick={toggleColorMode} />
      <Spacer flexGrow='2' />
      <Heading
        animation={`${animation} 4s linear alternate infinite`}
        bgGradient="linear(to-l, #0390FC, #FC0367)"
        backgroundSize= "200% auto"
        bgClip="text">
          url shortener by t9v
      </Heading>
      <Spacer />
      <form onSubmit={handleClick} method="post" width="300px">
          <Input
            type="text"
            value={data ? `https://s.t9v.ca/l/${data.short_url}` : input_url}
            onChange={handleChange}
            readOnly={data ? true : false}
            name="url"
            id="url"
            placeholder="Long URL here"
            required={true}
          />
        <Button type="submit">{data ? "Copy" : "Shorten"}</Button> 
      </form>
      <Spacer flexGrow='5' />
    </>
  )
};


export default function App() {
  
  const theme = extendTheme({initialColorMode:'system'});

  return (
    <ChakraProvider>
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" height="100vh" p="4">
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route path="/" element={ <MakeLink /> } />
          <Route path="/l/:short_url" element={ <ForwardLink /> } />
        </Routes>
      </Router>
    </Flex>
    </ChakraProvider>
  );
}


